<template>
  <div>
    <v-card elevation="0">
      <v-card-text>
        <div v-if="other_documents[0]">
          <v-row
            v-for="(item, index) in other_documents"
            :key="item.id"
            class="bank-statment-rows"
          >
            <v-col
              cols="12"
              class="border-bottom pl-0 d-flex align-center justify-space-between"
            >
              <div class="d-flex align-center">
                <div
                  class="mr-4 field-content"
                >
                  {{ index + 1 }}.
                </div>
                <div>
                  <p class="field-title">
                    Other Document Type:
                  </p>
                  <p class="field-content">
                    {{ item.document_name.split(".")[1].toUpperCase() }}
                  </p>
                </div>
              </div>
              <div>
                <v-btn
                  small
                  text
                  class="indigo lighten-5 py-4"
                  @click="
                    isImageDocument(item.document)
                      ? previewImage(item.document, item.document_type)
                      : viewDocument(item.document)
                  "
                >
                  <v-icon color="deep-purple darken-4">mdi-eye-outline</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <center>No documents avaliable</center>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active_loan_id: null,
      other_documents: [],
    };
  },
  mounted() {
    this.getActiveLoanId();
  },
  methods: {
    getActiveLoanId() {
      const self = this;
      let params = {
        customer_id: self.decrypt(this.$route.params.id),
      };
      console.log(params);
      const successHandler = (response) => {
        if (response.data.success) {
          if (response.data.loan_list[0]) {
            if (
              response.data.loan_list[0].loan_status == "Completed" ||
              response.data.loan_list[0].loan_status == "Rejected"
            ) {
              console.log();
            } else {
              self.active_loan_id = response.data.loan_list[0].loan_id;
              this.getOtherDocument();
            }
          }
        }
      };
      const finallyHandler = () => {};

      self.request_GET(
        self,
        self.$urls.LOAN_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    getOtherDocument() {
      const self = this;
      let params = {
        loan_id: self.active_loan_id,
        customer_id: self.decrypt(this.$route.params.id),
      };
      console.log(params);
      const successHandler = (response) => {
        if (response.data.success) {
          self.other_documents = response.data.other_doc_list;
        }
      };
      const finallyHandler = () => {};

      self.request_GET(
        self,
        self.$urls.NEW_CUSTOMER_GET_OTHER_DOCUMENT,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    viewDocument(url) {
      window.open(url, "_blank");
    },

    // returning true if image extension is png, jpg or, jpeg
    isImageDocument(url) {
      let ext = url.split(".");
      ext = ext[ext.length - 1];
      return ext == "png" || ext == "jpg" || ext == "jpeg" || ext == "webp";
    },
    // if isImageDocument returns true then opens image in new tab
    previewImage(url, document_name) {
      console.log("image preview", url);
      let image_url = url;

      let route = this.$router.resolve({
        name: "view-image",
        params: {
          image: this.encript(image_url),
          document: this.encript(document_name),
        },
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
<style scoped>
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.253);
}
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}
.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}
</style>
